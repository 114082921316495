
import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";

@Component({
  model: {
    prop: "checked",
    event: "click"
  }
})
export default class BtnCheck extends Vue {
  @Prop({ default: "会员抵扣" })
  btnText: string;
  @Prop({ default: false })
  checked: boolean;
  get $checked() {
    return this.checked;
  }
  handleStatusChange() {
    this.$emit("click", !this.checked);
  }
}
